import {DATE_FORMAT} from "../../constants";

export const signUpDataValidation = {
  fields: [
    "firstName",
    "lastName",
    "phone",
    "email",
    "addressLine1",
    "city",
    "state",
    "zipCode",
    "stomaMeasuringAppt",
    "surgeryDate",
  ],
  rules: {
    firstName: [{ rule: "isRequired", message: "First name is required" }],
    lastName: [{ rule: "isRequired", message: "Last name is required" }],
    phone: [
      { rule: "isPhone", message: "Mobile Number is invalid" },
      { rule: "isRequired", message: "Mobile Number is required" },
    ],
    email: [
      { rule: "isEmail", message: "Email is invalid" },
      { rule: "isRequired", message: "Email is required" },
    ],
    addressLine1: [
      { rule: "isRequired", message: "Address Line 1 is Required" },
    ],
    city: [{ rule: "isRequired", message: "City is Required" }],
    state: [{ rule: "isRequired", message: "State is Required" }],
    zipCode: [
      { rule: "isRequired", message: "Zip Code is Required" },
    ],
    stomaMeasuringAppt: [
      { rule: "isValidDate", message: `Appointment Date is Invalid (Format: ${DATE_FORMAT.toUpperCase()})` },
    ],
    surgeryDate: [
      { rule: "isValidDate", message: `Surgery Date is Invalid (Format: ${DATE_FORMAT.toUpperCase()})` },
    ],
  },
};

export const nameViewValidation = {
  fields: [
    "firstName",
    "lastName",
    "phone",
    "email"
  ],
  rules: {
    firstName: [{ rule: "isRequired", message: "First name is required" }],
    lastName: [{ rule: "isRequired", message: "Last name is required" }],
    phone: [
      { rule: "isPhone", message: "Mobile Number is invalid" },
      { rule: "isRequired", message: "Mobile Number is required" },
    ],
    email: [
      { rule: "isEmail", message: "Email is invalid" },
      { rule: "isRequired", message: "Email is required" },
    ]
  },
};

export const addressViewValidation = {
  fields: [
    "addressLine1",
    "city",
    "zipCode"
  ],
  rules: {
    addressLine1: [
      { rule: "isRequired", message: "Address Line 1 is Required" },
    ],
    city: [{ rule: "isRequired", message: "City is Required" }],
    zipCode: [
      { rule: "isRequired", message: "Zip Code is Required" },
    ]
  },
};

export const procedureDatesValidation = {
  fields: [
    "stomaMeasuringAppt",
    "surgeryDate",
  ],
  rules: {
    stomaMeasuringAppt: [
      { rule: "isValidDate", message: `Appointment Date is Invalid (Format: ${DATE_FORMAT.toUpperCase()})` },
    ],
    surgeryDate: [
      { rule: "isValidDate", message: `Surgery Date is Invalid (Format: ${DATE_FORMAT.toUpperCase()})` },
    ],
  },
}

export const procedureDetailsValidation = {
  fields: [
    "modifierIdentifier",
    "facilityId"
  ],
  rules: {
    modifierIdentifier: [{ rule: "isRequired", message: "Procedure is Required" }],
    facilityId: [{ rule: "isRequired", message: "Facility name is Required" }],
    stomaMeasuringAppt: [
      { rule: "isValidDate", message: `Appointment Date is Invalid (Format: ${DATE_FORMAT.toUpperCase()})` },
    ],
    surgeryDate: [
      { rule: "isValidDate", message: `Surgery Date is Invalid (Format: ${DATE_FORMAT.toUpperCase()})` },
    ],
  },
};

export const painLevelValidationConfig = {
  fields: ['painLevel'],
  rules: {
    painLevel: [
      { rule: 'isRequired', message: 'Pain level is required' },
    ],
  },
};

export const painPumpValidationConfig = {
  fields: ['painPump'],
  rules: {
    painPump: [
      { rule: 'isRequired', message: 'Pain Pump is required' },
    ],
  },
};

export const phoneValidationConfig = {
  fields: ['phone'],
  rules: {
    phone: [
      { rule: 'isPhone', message: 'Mobile Number is invalid' },
      { rule: 'isRequired', message: 'Mobile Number is required' },
    ],
  },
};

export const emailOtpValidationConfig = {
  fields: ['emailOtp'],
  rules: {
    emailOtp: [
      { rule: 'isRequired', message: 'OTP is required' },
    ],
  },
};

export const phoneOtpValidationConfig = {
  fields: ['phoneOtp'],
  rules: {
    phoneOtp: [
      { rule: 'isRequired', message: 'OTP is required' },
    ],
  },
};

export const procedureDateValidationConfig = {
  fields: ['procedureDate'],
  rules: {
    procedureDate: [
      { rule: 'isRequired', message: 'Procedure date is required' },
    ],
  },
};

export const dobValidationConfig = {
  fields: ['dob'],
  rules: {
    dob: [
      { rule: 'isRequired', message: 'Date of Birth is required' },
    ],
  },
};

export const surgeonValidationConfig = {
  fields: ['surgeon'],
  rules: {
    surgeon: [
      { rule: 'isRequired', message: 'Physician is required' },
    ],
  },
};

export const cardiologistValidationConfig = {
  fields: ['cardiologist'],
  rules: {
    cardiologist: [
      { rule: 'isRequired', message: 'Cardiologist is required' },
    ],
  },
};

export const emailValidationConfig = {
  fields: ['email'],
  rules: {
    email: [
      { rule: 'isEmail', message: 'Email is invalid' },
      { rule: 'isRequired', message: 'Email is required' },
    ],
  },
};

export const passwordValidationConfig = {
  fields: ['password'],
  rules: {
    password: [
      { rule: 'isPassword', message: 'Password is invalid' },
      { rule: 'isRequired', message: 'Password is required' },
    ],
  },
};
