import React from "react";
import Stack from "@mui/material/Stack";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import { patientRegisterReducer } from "./../reducers";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { IconButton } from "@material-ui/core";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import { generateOtp, sendTempData } from "./../action";
import DisclaimerView from "./DisclaimerView";
import { trimCountryCodeFromPhoneNumber } from 'utils/stringUtils'
import {
PHONE_VERIFY_VIEW, ADDRESS_VIEW, NAME_VIEW, SUCCESS_VIEW, PROCEDURE_DATES_VIEW, PROCEDURE_DETAILS_VIEW, 
} from "./../constants";
import { setSignUpData } from "./../action";
class DateConfirmView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flowId: props.flowId || null,
      flowType: props.flowType || "sign_up",
      firstName: props.firstName || "",
      lastName: props.lastName || "",
      email: props.email || "",
      phone: props.phone || "",
      addressLine1: props.addressLine1 || "",
      addressLine2: props.addressLine2 || "",
      city: props.city || "",
      state: props.state || "",
      zipCode: props.zipCode || "",
      stomaMeasuringAppt: props.stomaMeasuringAppt? moment(new Date(this.props.stomaMeasuringAppt)).format('MM/DD/YYYY') : null,
      surgeryDate: props.surgeryDate? moment(new Date(props.surgeryDate)).format('MM/DD/YYYY') : null,
      procedure: props.procedure || "",
      facilityName: props.facilityName || "",
      marketingConsent: props.marketingConsent || false,
      open: false,

    };
  }

  componentDidMount(){
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  componentWillReceiveProps(nextProps) {
    const formData = {};

    Object.keys(nextProps).forEach((key) => {
      if (key in this.state) {
        if (nextProps[key] != this.state[key]) {
          formData[key] = nextProps[key];
        }
      }
    });

    if (formData.length) {
      this.setState({ ...formData });
    }
  }

  onAgreeOfDisclaimer = () => {
    this.setState({ open: false },()=>{
      this.handleOTP();
    })
  };

  onDisagreeOfDisclaimer = () => {
    this.setState({ open: false });
  };

  onDisclaimerClose = () => {
    this.setState({ open: false });
  };

  openConsentModal = () =>{
    this.setState({open:true})
  }

  handleOTP = () => {
    const params = {
      first_name: this.props.firstName,
      last_name: this.props.lastName,
      email: this.props.email,
      contact_number: trimCountryCodeFromPhoneNumber(this.props.phone)[0] == '0' ? trimCountryCodeFromPhoneNumber(this.props.phone).replace('0','') : trimCountryCodeFromPhoneNumber(this.props.phone),
      location_code: this.props.facilityCode,
      procedure_modifier: this.props.modifierIdentifier.value,
      procedure_date: this.props.surgeryDate ? moment(new Date(this.props.surgeryDate)).format('YYYY-MM-DD') : null,
      stoma_marking_date: this.props.stomaMeasuringAppt ? moment(new Date(this.props.stomaMeasuringAppt)).format('YYYY-MM-DD') : null,
      email_verification_code: this.props.emailOtp,
      contact_number_verification_code: this.props.phoneOtp,
      flow_type: this.props.flowType,
      flow_id: this.props.flowId,
      password: this.state.password,
      password_confirmation: this.state.password,
      has_marketing_consent: this.props.marketingConsent,
      has_user_consented: this.props.serviceEnrollment,
      is_procedure_valid: this.props.isProcedureValid,
      facility_id: this.props.facilityId.value,
      education_preference: this.props.educationPreference.value,

      address:{
        line_1: this.props.addressLine1,
        line_2: this.props.addressLine2,
        city: this.props.city,
        state: this.props.state,
        zipcode: this.props.zipCode.trim().replaceAll('.','').replaceAll('-','')
      }
    };
    if(this.props.flowType === "mobile_sign_up"){
      this.props.sendTempData(params,this.props.nextPage,SUCCESS_VIEW);
    }
    else{
      this.props.generatePhoneOtp(params, this.props.nextPage, PHONE_VERIFY_VIEW );
    }
  };
  fullAddress = () => {
    let address = "";
    address = address !="" ? ( this.state.addressLine1 ? `${address}, ${this.state.addressLine1}` : `${address}`) : `${this.state.addressLine1}`;
    address = address !="" ? ( this.state.addressLine2 ? `${address}, ${this.state.addressLine2}` : `${address}`) : `${this.state.addressLine2}`;
    address = address !="" ? ( this.state.city ? `${address}, ${this.state.city}` : `${address}`) : `${this.state.city}`;
    address = address !="" ? ( this.state.state ? `${address}, ${this.state.state}` : `${address}`) : `${this.state.state}`;
    address = address !="" ? ( this.state.zipCode ? `${address}, ${this.state.zipCode}` : `${address}`) : `${this.state.zipCode}`;
    return address
  }

  render() {
    return (

          <div className="data-confirm-view">

            <div  className="text-left data-confirm-body">
              <Stack spacing={"24px"}>
              <div style={{
                    display:'flex',
                    alignItems: "center",
                    justifyContent: 'space-between'
                  }}>
                  <div className="data-verification-section-title">Basic Information</div>
                    <div className="float-right">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => this.props.nextPage(NAME_VIEW, true)}
                        label="edit"
                      >
                        <EditOutlinedIcon className="edit-data-icon" size="large"  />
                      </IconButton>
                    </div>
                  </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">Full Name</div>

                    <div className="data-verification-value">
                      {this.props.firstName} {this.state.lastName}
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">
                      Mobile Phone Number
                    </div>
                    <div className="data-verification-value">
                      {this.props.phone}
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">Email Address</div>
                    <div className="data-verification-value">
                      {this.state.email}
                    </div>
                  </Stack>
                </div>
                  <hr className="hr-line-light"/>
                <div style={{
                    display:'flex',
                    alignItems: "center",
                    justifyContent: 'space-between'
                  }}>
                  <div className="data-verification-section-title">Address</div>
                    <div className="float-right">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => this.props.nextPage(ADDRESS_VIEW, true)}
                        label="edit"
                      >
                        <EditOutlinedIcon className="edit-data-icon" size="large"  />
                      </IconButton>
                    </div>
                  </div>
                <div>
                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">Address Line</div>
                    <div className="data-verification-value">
                    {this.fullAddress()}
                    </div>
                  </Stack>
                </div>
                    <hr className="hr-line-light"/>
                <div style={{
                    display:'flex',
                    alignItems: "center",
                    justifyContent: 'space-between'
                  }}>
                  <div className="data-verification-section-title">Surgery Dates</div>
                    <div className="float-right">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => this.props.nextPage(PROCEDURE_DATES_VIEW,true)}
                        label="edit"
                      >
                        <EditOutlinedIcon className="edit-data-icon" size="large"  />
                      </IconButton>
                    </div>
                  </div>
                {
                <div>
                 <Stack spacing={"16px"}>
                    <div className="data-verification-title">
                      Stoma Measuring Appointment Date
                    </div>
                    <div className="data-verification-value">
                      {this.state.stomaMeasuringAppt
                        ? this.state.stomaMeasuringAppt
                        : <span className="not-available-data">Not Entered</span>}
                    </div>
                  </Stack>

                </div>
  }
   {
                <div>

                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">Surgery Date</div>
                    <div className="data-verification-value">
                      {this.state.surgeryDate
                        ? this.state.surgeryDate
                        : <span className="not-available-data">Not Entered</span>}
                    </div>
                  </Stack>

                </div>

                      }
                    <hr className="hr-line-light"/>
                <div style={{
                    display:'flex',
                    alignItems: "center",
                    justifyContent: 'space-between'
                  }}>
                  <div className="data-verification-section-title">Procedure Information</div>
                    <div className="float-right">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => this.props.nextPage(PROCEDURE_DETAILS_VIEW,true)}
                        label="edit"
                      >
                        <EditOutlinedIcon className="edit-data-icon" size="large"  />
                      </IconButton>
                    </div>
                  </div>
                <div>
                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">Procedure</div>
                    <div className="data-verification-value">
                      {this.props.modifierIdentifier.label}
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">Facility Name</div>
                    <div className="data-verification-value">
                      {this.props.facilityId.label}
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">Education Preference</div>
                    <div className="data-verification-value">
                      {this.props.educationPreference && this.props.educationPreference.label || "View for my procedure only"}
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack sx={{ alignSelf: "center" }} spacing={"16px"}>
                    <PrimaryButton
                      sx={{ alignSelf: "center" }}
                      onClick={this.handleOTP}
                      text="Yes, It’s correct"
                      fullWidth
                    />
                  </Stack>
                </div>
              </Stack>
            </div>
            <DisclaimerView
          open={this.state.open}
          onClose={this.onDisclaimerClose}
          onAgree={this.onAgreeOfDisclaimer}
          onDisagree={this.onDisagreeOfDisclaimer}
        />
          </div>
      //   </div>
      // </div>
    );
  }
}

DateConfirmView.propTypes = {
  flowId: PropTypes.string,
  isLoading: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
  stomaMeasuringAppt: PropTypes.object,
  surgeryDate: PropTypes.object,
  procedure: PropTypes.string,
  facilityName: PropTypes.string,
  marketingConsent: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  firstName: state.patientRegister.patientRegisterReducer.firstName,
  lastName: state.patientRegister.patientRegisterReducer.lastName,
  email: state.patientRegister.patientRegisterReducer.email,
  phone: state.patientRegister.patientRegisterReducer.phone,
  addressLine1: state.patientRegister.patientRegisterReducer.addressLine1,
  addressLine2: state.patientRegister.patientRegisterReducer.addressLine2,
  city: state.patientRegister.patientRegisterReducer.city,
  state: state.patientRegister.patientRegisterReducer.state,
  zipCode: state.patientRegister.patientRegisterReducer.zipCode,
  stomaMeasuringAppt:
    state.patientRegister.patientRegisterReducer.stomaMeasuringAppt,
  surgeryDate: state.patientRegister.patientRegisterReducer.surgeryDate,
  procedure: state.patientRegister.patientRegisterReducer.procedure,
  facilityName: state.patientRegister.patientRegisterReducer.facilityName,
  marketingConsent:
    state.patientRegister.patientRegisterReducer.marketingConsent,
    flowType: state.patientRegister.patientRegisterReducer.flowType,
    flowId:
      state.patientRegister.patientRegisterReducer.flowId,
  modifierIdentifier: state.patientRegister.patientRegisterReducer.modifierIdentifier,
  facilityId: state.patientRegister.patientRegisterReducer.facilityId,
  educationPreference: state.patientRegister.patientRegisterReducer.educationPreference,
  serviceEnrollment: state.patientRegister.patientRegisterReducer.serviceEnrollment
});

const mapDispatchToProps = (dispatch) => ({
  setSignUpDataInStore: (data) => dispatch(setSignUpData(data)),
  generatePhoneOtp: (params, nextPage,page) =>
    dispatch(generateOtp(params, nextPage,page)),
  sendTempData: (params,nextPage,page) =>
    dispatch(sendTempData(params,nextPage,page)),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "patientRegister",
  reducer: combineReducers({
    patientRegisterReducer
  }),
});

export default compose(withReducer, withConnect)(DateConfirmView);
