import React,{useState,useEffect} from "react";
import welcome3x from "assets/images/welcome@3x.png";
import welcome2x from "assets/images/welcome@2x.png";
import welcome1x from "assets/images/welcome@1x.png";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import SecondaryButton from "./../../../components/StyleGuideComponents/SecondaryButton";
import "../patientRegister.scss";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Media from "react-media";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import { patientRegisterReducer } from "../reducers";
import { setFlagForExistingUser, clearFormDataInRedux} from './../action';
import { getItemFromStorage } from "services/storage";
import isEmpty from "lodash/isEmpty";
import { signOutUser } from 'common/authThunkActions';
import UserCookieConsent from '../../../components/UserCookieConsent';
import { NAME_VIEW, LOGIN_VIEW } from "./../constants";

const WelcomeView = (props) => {

  const [isSignedIn, setIsSignedIn] = useState(props.isSignedIn)
  
  useEffect(()=>{
    setIsSignedIn(props.isSignedIn)
  },[props.isSignedIn])

  useEffect(()=>{
    props.clearFormData()
    if(isSignedIn){
      props.signOut("")
    }
  },[])
  return (
    <div className="row no-margin signup-background">
      <div className="col-md-6 no-padding image-container">
      <Media query={{ maxWidth: 991 }}>
      {
        (screenIsSmall) => screenIsSmall
          ? (
            <Media query={{ maxWidth: 480 }}>
            {
              (screenIsSmall) => screenIsSmall
                ? (
              <img src={welcome1x} className="hero-image" alt="welcome-image"></img>
                ) : (
              <img src={welcome2x} className="hero-image" alt="welcome-image"></img>

                )
            }
          </Media>
          ) : (
        <img src={welcome3x} className="hero-image" alt="welcome-image"></img>
          )
      }
    </Media>
      </div>
      <div className="col-md-6 text-container-wrapper no-padding">
        <Container maxWidth="lg">
          <Box className="welcome-info-container">
            <Box className="welcome-info">
              <div className="secure-start-title-text">
                Secure Start<sup ><span className="sup-text" style={{fontSize:"12px"}}>SM</span></sup> Services
              </div>
              <div className="welcomeSubTitleText">
                Ostomy Journey Companion App
              </div>

              <p className="secure-start-help-info">
              Hi there! At Secure Start<sup className="sup-text" style={{fontSize:"10px"}}>SM</sup> Services, we’re here to help support you on your journey - from hospital to home and beyond. Let’s get started.
              </p>
              <Media query={{ maxHeight:"480px"}}>
                {
                  (screenIsSmall) =>(
                    screenIsSmall?(
                      <div style={{padding:"0 15px"}}>
                      <Stack spacing={"10px"}>
                      <div>
                        <PrimaryButton onClick={()=>{props.setExistingUserFlag(false);
                      props.nextPage(NAME_VIEW)}}  text="I am a new app user"  />
                      </div>
                      <div>
                        <SecondaryButton  onClick={()=>{
                        props.setExistingUserFlag(true)
                        props.nextPage(LOGIN_VIEW)
                      }}  text="I have an existing app account"  />
                      </div>
                      </Stack>
                      </div>
                    ):(
                      <Stack spacing={"20px"}>
                      <div >
                        <PrimaryButton onClick={()=>{props.setExistingUserFlag(false);
                      props.nextPage(NAME_VIEW)}} text="I am a new app user"  />
                      </div>
                      <div>
                        <SecondaryButton onClick={()=>{
                        props.setExistingUserFlag(true)
                        props.nextPage(LOGIN_VIEW)
                      }} text="I have an existing app account"  />
                      </div>
                      </Stack>
                    )
                  )
                }
              </Media>
            </Box>
          </Box>
        </Container>
      </div>
      <UserCookieConsent/>
    </div>
  );
};

WelcomeView.propTypes = {
  isLoading: PropTypes.bool,
  firstName: PropTypes.string,
  email: PropTypes.string,
  isSignedIn: PropTypes.bool.isRequired,
  signOut: PropTypes.func,
  procedure: PropTypes.string
};

const mapStateToProps = (state) => ({
  existingUser: state.patientRegister.patientRegisterReducer.existingUser,
  isSignedIn:
    state.currentUser.isSignedIn || !isEmpty(getItemFromStorage("token")),
    procedure: state.patientRegister.patientRegisterReducer.procedure,
    isValidQR: state.patientRegister.patientRegisterReducer.isValidQR
});

const mapDispatchToProps = (dispatch) => ({
  setExistingUserFlag: (params) => dispatch(setFlagForExistingUser(params)),
  signOut: (postLogoutRedirectUrl=null) => dispatch(signOutUser(postLogoutRedirectUrl)),
  clearFormData: () => dispatch(clearFormDataInRedux())
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "patientRegister",
  reducer: combineReducers({
    patientRegisterReducer,
  }),
});

export default compose(withReducer, withConnect)(WelcomeView);
