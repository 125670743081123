import React from "react";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import { patientRegisterReducer} from "./../reducers";
import { privacyNoticeReducer } from "../../../common/authReducers";
import { setSignUpData } from "./../action";
import DisclaimerView from "./DisclaimerView";
import { DATA_CONFIRM_VIEW } from "./../constants";
import { withRouter } from 'react-router';
import {colors} from '../../../components/StyleGuideComponents/StyleGuideCSS'
import { getPrivacyNoticeUrl } from "common/authThunkActions";

class ServiceAgreementView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marketingConsent: props.marketingConsent || false,
      serviceEnrollment: props.serviceEnrollment || false,
      errors: [],
      serviceEnrollmentModal: false,
      nextButtonDisabled: true,
      privacyNoticeUrl: "",
    };
  }

  componentWillUnmount(){
    this.props.setSignUpDataInStore(this.state)
  }

  componentWillReceiveProps = (nextProps) => {

    const formData = {};
    Object.keys(nextProps).forEach((key) => {
      if (key in this.state) {

        if (nextProps[key] != this.state[key]) {
          formData[key] = nextProps[key];
        }
      }
    });
    if (Object.keys(formData).length) {
      this.setState({ ...formData });
    }
  };

  componentDidMount= () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.props.getPrivacyNoticeUrl()
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if(!this.state.serviceEnrollment){
    this.setState({ serviceEnrollmentModal: true,nextButtonDisabled:true });
    }
    else{
    this.props.setSignUpDataInStore(this.state, this.props.nextPage, DATA_CONFIRM_VIEW);
    }
  };

  onAgreeOfDisclaimer = () => {
    this.setState({ serviceEnrollmentModal: false });
  };

  onDisagreeOfDisclaimer = () => {
    this.setState({ serviceEnrollmentModal: false },()=>{
      this.props.history.goBack();
      this.props.history.push(this.props.match.url)
      window.location.reload();
    });
  };

  onDisclaimerClose = () => {
    this.setState({ serviceEnrollmentModal: false });
  };

  render() {
    return (
      <div style={{paddingBottom:"-6%"}}>

        <DisclaimerView
          title="You didn’t agree to the service enrollment consent."
          body="This service is provided by Secure Start℠
           and we are unable to proceed and process your data without your consent."
          primaryButtonText="Return to form"
          secondaryButtonText="Exit app sign up"
          open={this.state.serviceEnrollmentModal}
          onClose={this.onDisclaimerClose}
          onAgree={this.onAgreeOfDisclaimer}
          onDisagree={this.onDisagreeOfDisclaimer}
        />

        <form type="post">
          <div>
            <Stack sx={{zIndex:"0"}} spacing={"24px"}>
              <div

                style={{ textAlign: "left", fontSize: "16px", fontWeight: "400" }}
              >
                <FormControlLabel
                  label={
                     <>Service Enrollment <sup className="mandatory-asterisk">*</sup>:</>
                  }
                  control={
                    <Checkbox
                    style={{color: colors.colorPrimaryDark}}
                    size="large"
                      checked={this.state.serviceEnrollment}
                      onChange={() => {
                        this.setState({
                          serviceEnrollment: !this.state.serviceEnrollment,
                        });
                      }}
                    />
                  }
                />
                <p
                  className="body marketing-consent-checkbox"
                  style={{ fontSize: "14px", paddingLeft: "28px" }}
                >
                  For the purpose of enrolling in a free consumer service program and receiving the associated membership benefits, I consent to the processing of my personal identification, communication preference, health information, product information, third party information, transactional information, and request details. I understand that these services are free of charge, and there is no obligation to purchase anything to receive them. I understand that I am under no obligation to participate in any/ all of the applicable membership benefits, such as assistance identifying product supplier options, and receiving product samples and other complimentary items. I understand that Hollister may communicate with me about consumer services, or as part of a membership benefit, using the contact information (postal address, email, phone, & sms text) that I have provided. Hollister reserves the right to change the consumer service programs (Magokoro, QualiVida, Secure Start<sup className="sup-text">SM</sup>, & Vivre+) at any time.<br/><br/> 
                  Hollister may also share my personal and health information with my nurse, doctor, healthcare product supplier, other healthcare professional or other third parties as indicated in the data privacy notice, which is attached to this form and can also be found at&nbsp;
                  <a className="cursor-pointer" href="https://www.hollister.com/en/privacypolicy" target="_blank" ><u className="privacy-notice-url">https://www.hollister.com/en/privacypolicy</u></a>
                </p>
              </div>

              <div

style={{ textAlign: "left", fontSize: "16px",fontWeight: "400" }}
>
<FormControlLabel
  label={
    "Direct Marketing: "
  }
  control={
    <Checkbox
      style={{color: colors.colorPrimaryDark,position:"relative"}}
      size="large"
      checked={this.state.marketingConsent}
      onChange={() => {
        this.setState({
          marketingConsent: !this.state.marketingConsent,
        });
      }}
    />
  }
/>
<p
  className="body marketing-consent-checkbox"
  style={{ fontSize: "14px", paddingLeft: "28px" }}
>
For the purpose of receiving information about products, promotions, and other direct marketing campaigns, I consent to the processing of my personal identification, communication preference, health information, and request details. I understand that Hollister may communicate with me about these topics, using the contact information (postal address, email, phone, & sms text) that I have provided.
</p>

<div className="privacy-notice">
<div className="privacy-notice-title">Privacy Notice</div>
<div
  className="body marketing-consent-checkbox"
  style={{ fontSize: "14px", paddingLeft: "28px" }}
>
<p className="privacy-notice-text">Your consent is optional and you have the right to withdraw it at any time. To withdraw your consent or to make changes to your communication preferences, contact us at <a className="cursor-pointer" href="mailto:unsubscribe@hollister.com" target="_blank" ><u className="privacy-notice-url">unsubscribe@hollister.com.</u></a> Such withdrawal only has future effect (i.e. the withdrawal of your consent has no effect on the lawfulness of the data processing and disclosures before the withdrawal was made).<br/><br/>

Please find further details regarding the processing of your personal data and your legal rights in our full
<a className="cursor-pointer" href={this.props.privacyNoticeUrl} target="_blank" >
  <u className="privacy-notice-url">
    &nbsp;Privacy Notice</u></a>.</p>
</div>
</div>
</div>



            </Stack>
            <div className="continue-button-for-form">
              <PrimaryButton
                fullWidth
                text={this.props.nextButtonText}
                onClick={this.handleSubmit}
              />
              </div>
          </div>
        </form>

      </div>
    );
  }
}

ServiceAgreementView.propTypes = {
  marketingConsent: PropTypes.bool,
  getPrivacyNoticeUrl: PropTypes.func,
};

const mapStateToProps = (state) => ({
  marketingConsent:
    state.patientRegister.patientRegisterReducer.marketingConsent,
  serviceEnrollment:
    state.patientRegister.patientRegisterReducer.serviceEnrollment,
  privacyNoticeUrl: state.patientRegister.privacyNoticeReducer.privacyNoticeUrl
});

const mapDispatchToProps = (dispatch) => ({
  setSignUpDataInStore: (data, nextPage, page) =>
    dispatch(setSignUpData(data, nextPage, page)),
  getPrivacyNoticeUrl: () =>
    dispatch(getPrivacyNoticeUrl())
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "patientRegister",
  reducer: combineReducers({
    patientRegisterReducer,
    privacyNoticeReducer
  }),
});

export default compose(withReducer, withRouter,withConnect)(ServiceAgreementView);

