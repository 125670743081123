import React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import InputTextField from "./../../../components/StyleGuideComponents/InputTextField";
import DatePicker from "./../../../components/StyleGuideComponents/DatePicker";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import { patientRegisterReducer } from "./../reducers";
import validate, { clearErrorsForField } from "common/validator";
import { signUpDataValidation } from "./../validators";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { setSignUpData } from "./../action";
import DisclaimerView from "./DisclaimerView";
import { generateOtp, verifyOtp } from "../action";
import title from "./../../../assets/images/securestart3x.png";
import Box from "@mui/material/Box";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Media from "react-media";
import NameView from "./NameView";
import { NAME_VIEW } from "../constants";

class Wrapper extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="wrapper-outer-body">
        { this.props.showBanner && <div className="wrapper-banner-container">{this.props.bannerMessage}</div>}


        <div className="wrapper-body">
        <div className="header-wrapper">
          <div className="sign-up-header">
            {
              this.props.flowType !== 'mobile_sign_up' || this.props.page !== NAME_VIEW ? 
            <Media query={{ maxWidth: 480 }}>
              {(screenIsSmall) =>
                screenIsSmall ? <ArrowBackOutlinedIcon sx={{width:"24px",height:"24px"}} onClick={()=>this.props.nextPage(this.props.prevPage)} style={{alignSelf:"flex-start"}} fontSize="large" /> :<ArrowBackIosIcon className="back-icon" sx={{width:"24px",height:"24px"}}  onClick={()=>this.props.nextPage(this.props.prevPage)} fontSize="normal" />
              }
            </Media>:
            <div style={{alignSelf:"flex-start",marginRight: "30px"}}></div>
            } 
            
          </div>
        { this.props.page >= 2 && this.props.page <= 6 ? 
        <div className="progress-bar-container">
            <div className={`eclipse-progress-dot ${this.props.page >= 2 ? 'dark-circle':''}`}/>
            <div className={`eclipse-progress-dot ${this.props.page >= 3 ? 'dark-circle':''}`}/>
            <div className={`eclipse-progress-dot ${this.props.page >= 4 ? 'dark-circle':''}`}/>
            <div className={`eclipse-progress-dot ${this.props.page >= 5 ? 'dark-circle':''}`}/>
            <div className={`eclipse-progress-dot ${this.props.page >= 6 ? 'dark-circle':''}`}/>
        </div>
        :
         this.props.page >= 12 && this.props.page <= 13 &&
        <div className="progress-bar-container progress-bar-container-second">
          <div className={`eclipse-progress-dot ${this.props.page >= 12 ? 'dark-circle':''}`}/>
          <div className={`eclipse-progress-dot ${this.props.page >= 13 ? 'dark-circle':''}`}/>
        </div>
        
        }
       
          </div>
          <img className="img-fluid header-img" src={title}></img>
          <div className="wrapper-inner-body">
          {this.props.title1 && (<div className={`wrapper-title-one ${[9,10,12,13].includes(this.props.page) ? 'wrapper-centered-texts' : '' } `}>{this.props.title1}</div>)}
          {this.props.title2 && (<div className="wrapper-title-two">{this.props.title2}</div>)}
          {<div className="procedure-dates-note-text">{this.props.page === 4 && "*Note: If you do not know your surgery date, it can be added later."}</div>}

            {this.props.children}
          </div>
      </div>
      </div>
    );
  }
}

Wrapper.propTypes = {

};

const mapStateToProps = (state) => ({
  page: state.patientRegister.patientRegisterReducer.page,
  flowType: state.patientRegister.patientRegisterReducer.flowType
});

const mapDispatchToProps = (dispatch) => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "patientRegister",
  reducer: combineReducers({
    patientRegisterReducer,
  }),
});

export default compose(withReducer, withConnect)(Wrapper);
