import React, {useEffect} from "react";
import success1x from "assets/images/success@1x.png";
import success2x from "assets/images/success@2x.png";
import success3x from "assets/images/success@3x.png";
import googlePlayIcon from "assets/images/googelplayicon.png";
import appStoreIcon from "assets/images/applestoreicon.png";
import "../patientRegister.scss";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Media from "react-media";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {combineReducers, compose} from "redux";
import injectReducer from "utils/injectReducer";
import {patientRegisterReducer} from "../reducers";
import {getItemFromStorage} from "services/storage";
import isEmpty from "lodash/isEmpty";
import {signOutUser} from 'common/authThunkActions';
import {APP_STORE_URL, GOOGLE_PLAY_STORE_URL} from 'services/constants';
import strings from "../../../strings";
import PrimaryButton from "../../../components/StyleGuideComponents/PrimaryButton";
import { isiOS } from "../../../utils/common";


const SuccessView = (props) => {
    useEffect(() => {
        if (props.isSignedIn) {
            props.signOut("")
        }
        if(props.flowType === 'mobile_sign_up'){
        let win = null;
        if (isiOS) {
        win = window.open('');
        }
        if (isiOS && win) {
        win.location = props.deepLinkUrl;
        } else {
        win = window.location.assign(props.deepLinkUrl, '_blank');
        }
        if (!(win === undefined || win === null)) {
        win.focus();
        }
        }
    }, [])
    return (
        <div className="row no-margin signup-background">
            <div className="col-md-6 no-padding image-container">
                <Media query={{maxWidth: 991}}>
                    {
                        (screenIsSmall) => screenIsSmall
                            ? (
                                <Media query={{maxWidth: 480}}>
                                    {
                                        (screenIsSmall) => screenIsSmall
                                            ? (
                                                <img src={success1x} className="hero-image" alt="welcome-image"></img>
                                            ) : (
                                                <img src={success2x} className="hero-image" alt="welcome-image"></img>

                                            )
                                    }
                                </Media>
                            ) : (
                                <img src={success3x} className="hero-image" alt="welcome-image"></img>
                            )
                    }
                </Media>
            </div>
            <div className="col-md-6 text-container-wrapper no-padding">
                <Container maxWidth="lg">
                    <Box className="welcome-info-container">
                        <Box className="welcome-info">
                            <div className="success-secure-start-title-text">
                                All Set!
                            </div>
                            <div className="successSubTitleText">
                                { props.flowType !== 'mobile_sign_up' ?
                                "Download and Login to Secure Start℠ to finish signing up.":
                                "Thanks!"
                                }
                                {props.deepLinkUrl && 
                            <>
                            <p><div dangerouslySetInnerHTML={{__html:  strings.SUCCESS_VIEW_URL_TEXT.replace("deepLinkUrl",props.deepLinkUrl) }}></div></p>
                            </>
                            }
                            </div>
                            {
                             props.flowType !== 'mobile_sign_up' && 
                            <Media query={{maxHeight: 480}}>
                                {
                                    (screenIsSmall) => screenIsSmall ? (
                                        <div className="app-download-icons">
                                            <Stack spacing={"10px"}>
                                                <div><a href={GOOGLE_PLAY_STORE_URL} target="_blank">
                                                    <img
                                                        src={googlePlayIcon}
                                                        className="store-image"
                                                        alt="google-play-store"
                                                    />
                                                </a></div>
                                                <div>
                                                    <a href={APP_STORE_URL} target="_blank">
                                                        <img
                                                            src={appStoreIcon}
                                                            className="store-image"
                                                            alt="apple-play-store"
                                                        />
                                                    </a>
                                                </div>
                                            </Stack>
                                        </div>
                                    ) : (
                                        <div className="app-download-icons">
                                            <Stack spacing={"24px"}>
                                                <div><a href={GOOGLE_PLAY_STORE_URL} target="_blank">
                                                    <img
                                                        src={googlePlayIcon}
                                                        className="store-image"
                                                        alt="google-play-store"
                                                    />
                                                </a></div>
                                                <div>
                                                    <a href={APP_STORE_URL} target="_blank">
                                                        <img
                                                            src={appStoreIcon}
                                                            className="store-image"
                                                            alt="apple-play-store"
                                                        />
                                                    </a>
                                                </div>
                                            </Stack>
                                        </div>
                                    )

                                }

                            </Media>
                            }


                            <p className="success-page-secure-start-help-info">
                                You can contact your Secure Start℠ advisor on&nbsp;
                                <Media query={{maxWidth: 480}}>
                                    {
                                        (screenIsSmall) => screenIsSmall
                                            ? (
                                                <span className="highlight-text contact-number">
                        <a href="tel: +448003761310">
                        +44 800 3761310 </a></span>
                                            ) : (
                                                <span className="highlight-text contact-number">
                      +44 800 3761310</span>

                                            )
                                    }
                                </Media>
                            </p>

                        </Box>
                    </Box>
                </Container>
            </div>
        </div>

    );
};

SuccessView.propTypes = {
    isLoading: PropTypes.bool,
    firstName: PropTypes.string,
    email: PropTypes.string,
    isSignedIn: PropTypes.bool.isRequired,
    signOut: PropTypes.func,

};

const mapStateToProps = (state) => ({
    isSignedIn:
        state.currentUser.isSignedIn || !isEmpty(getItemFromStorage("token")),
    flowType: state.register.patientRegisterReducer.flowType,
    deepLinkUrl: state.register.patientRegisterReducer.deepLinkUrl
});

const mapDispatchToProps = (dispatch) => ({
    signOut: (postLogoutRedirectUrl = null) => dispatch(signOutUser(postLogoutRedirectUrl)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
    key: "patientRegister",
    reducer: combineReducers({
        patientRegisterReducer,
    }),
});

export default compose(withReducer, withConnect)(SuccessView);
