import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";

import Grid from "@mui/material/Grid";
import welcome1x from "assets/images/welcome@1x.png";
import welcome2x from "assets/images/welcome@2x.png";
import welcome3x from "assets/images/welcome@3x.png";
import "./patientRegister.scss";
import PrimaryButton from "./../../components/StyleGuideComponents/PrimaryButton";
import * as Constants from "./constants";
import WelcomeView from "./components/WelcomeView";
import DataConfirmView from "./components/DataConfirmView";
import NameView from "./components/NameView";
import AddressView from "./components/AddressView";
import ProcedureDatesView from "./components/ProcedureDatesView";
import ProcedureDetailsView from "./components/ProcedureDetailsView";
import ServiceAgreementView from "./components/ServiceAgreementView";
import EmailOTPVerifyView from "./components/EmailOTPVerifyView";
import MobileOTPVerifyView from "./components/MobileOTPVerifyView";
import ProcedureVerificationView from "./components/ProcedureVerificationView";
import SuccessView from "./components/SuccessView";
import ForgotPasswordView from "./components/ForgotPasswordView";
import LoginView from "./components/LoginView";
import LoggedInUserProcedureView from "./components/LoggedInUserProcedureView";
import PasswordView from "./components/PasswordView";
import PasswordResetSuccessView from "./components/PasswordResetSuccessView";
import { patientRegisterReducer } from "./reducers";
import injectReducer from "utils/injectReducer";
import { generateOtp, verifyOtp } from "./action";
import LightboxModal from './../../components/LightboxModal';
import {
  getAllLocationsRequest,
  getAllProceduresModifiersRequest,
} from "common/actions";
import {
  allLocationsReducer,
  allProceduresModifiersReducer,
} from "common/reducer";

import { pageChange, setQRCodedetails, getFlowId } from "./action";
import history from "../../configureHistory";
import Wrapper from './components/Wrapper'
import {
  WELCOME_VIEW, NAME_VIEW, DATA_CONFIRM_VIEW, PHONE_VERIFY_VIEW, EMAIL_VERIFY_VIEW, 
  PROCEDURE_VERIFICATION_VIEW, SUCCESS_VIEW, LOGIN_VIEW, LOGGED_IN_USER_PROCEDURE_VIEW, FORGOT_PASSWORD_VIEW,
  ADDRESS_VIEW, PROCEDURE_DATES_VIEW, PROCEDURE_DETAILS_VIEW, SERVICE_AGREEMENT_VIEW
  } from "./constants";
import reducer from './../ForgotPassword/reducer';
import strings from "../../strings";


class PatientRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      page: this.props.page || 0,
      flowType: props.flowType || "sign_up",
      flowId: props.flowId,
      phone: props.phone,
      isMfaEnabled: this.props.isMfaEnabled,
      existingUser: props.existingUser || false,
      firstName: this.props.firstName,
      loggedInProcedureDetailsTitle: strings.LOGGEDIN_PROCEDURE_DETAILS_TITLE,
      isFromDataConfirmPage: false
    };
  }

  changeloggedInProcedureDetailsTitle = (title) => {
    this.setState({loggedInProcedureDetailsTitle: title })
  }

  goToNextPage = (page, isFromDataConfirmPage) => {
      if(isFromDataConfirmPage) this.setState({isFromDataConfirmPage});
      else this.setState({isFromDataConfirmPage: false});
      this.props.handlePageChange(this.state.isFromDataConfirmPage && this.props.page !== DATA_CONFIRM_VIEW ? DATA_CONFIRM_VIEW : page);
  };

  switchToAddProcedureFlow = () => {
    this.props.handlePageChange(this.state.page + 8);
  }
  switchToSignUpFlow = () => {
    this.props.handlePageChange(this.state.page - 8);
  }



  componentDidMount = () => {
    this.props.setQRCodedetailsInState(
      this.props.match.params.procedure,
      this.props.match.params.facility_name
    );
    this.props.getFlowId({ flow_type: this.state.flowType });
    this.props.getAllLocations();
    this.props.getAllProceduresModifiers();
    if(this.props.currentUser.role !== 'Patient') this.changeloggedInProcedureDetailsTitle(strings.LOGGEDIN_PROCEDURE_DETAILS_UNAUTHORIZED_TITLE);
  };

  componentDidUpdate = () => {
    // if (history.location.hash) {
    //   let newPage = parseInt(history.location.hash.replace("#", ""), 10);
    //   if (newPage !== this.state.page) {
    //     this.props.handlePageChange(newPage, true);
    //   }
    // }

    // if (this.state.flowId !== this.props.flowId) {
    //   this.setState({ flowId: this.props.flowId });
    // }
  };

  componentWillReceiveProps = (nextProps) => {

    const formData = {};

    Object.keys(nextProps).forEach((key) => {
      if (key in this.state) {
        if (nextProps[key] != this.state[key]) {
          formData[key] = nextProps[key];
        }
      }
    });

    if (formData.length) {
      this.setState({ ...formData });
    }

    let data = {};

    if (nextProps.page != this.state.page) {
      data["page"] = nextProps.page;
    }
    if (this.state.flowId !== nextProps.flowId) {
      data["flowId"] = nextProps.flowId;
    }
    if (this.state.isMfaEnabled !== nextProps.isMfaEnabled) {
      data["isMfaEnabled"] = nextProps.isMfaEnabled;
    }

    if (this.state.isLoading !== nextProps.isLoading) {
      data["isLoading"] = nextProps.isLoading;
    }

    if (this.state.existingUser !== nextProps.existingUser) {
      data["existingUser"] = nextProps.existingUser;
    if (this.state.firstName !== nextProps.firstName) {
      data["firstName"] = nextProps.firstName;
    }
  }
    this.setState({ ...data });

  };



  renderView = () =>{
    // render(){
    switch(this.state.page){
      case Constants.NAME_VIEW:
        return <Wrapper nextPage={this.goToNextPage} prevPage={WELCOME_VIEW} title1={strings.NAME_VIEW_MESSAGE_HEADING} title2={strings.NAME_VIEW_MESSAGE_BODY} >
                <NameView nextPage={this.goToNextPage} prevPage={this.goToPrevPage} nextButtonText={this.state.isFromDataConfirmPage ? strings.SERVICE_AGREEMENT_VIEW_BUTTON_TEXT : strings.NAME_VIEW_NEXT_BUTTON_TEXT}/>
              </Wrapper>;
      case Constants.ADDRESS_VIEW:
        return <Wrapper nextPage={this.goToNextPage} prevPage={NAME_VIEW} title1={strings.ADDRESS_VIEW_MESSAGE_HEADING} title2={strings.ADDRESS_VIEW_MESSAGE_BODY} >
                <AddressView nextPage={this.goToNextPage} prevPage={this.goToPrevPage} nextButtonText={this.state.isFromDataConfirmPage ? strings.SERVICE_AGREEMENT_VIEW_BUTTON_TEXT : strings.ADDRESS_VIEW_BUTTON_TEXT} />
              </Wrapper>;
      case Constants.PROCEDURE_DATES_VIEW:
        return <Wrapper nextPage={this.goToNextPage} prevPage={ADDRESS_VIEW} title1={strings.PROCEDURE_DATES_VIEW_MESSAGE_HEADING} title2={strings.PROCEDURE_DATES_VIEW_MESSAGE_BODY} >
                <ProcedureDatesView nextPage={this.goToNextPage} prevPage={this.goToPrevPage} nextButtonText={this.state.isFromDataConfirmPage ? strings.SERVICE_AGREEMENT_VIEW_BUTTON_TEXT : strings.PROCEDURE_DATES_VIEW_BUTTON_TEXT} />
              </Wrapper>;
      case Constants.PROCEDURE_DETAILS_VIEW:
        return <Wrapper nextPage={this.goToNextPage} prevPage={PROCEDURE_DATES_VIEW} title1={strings.PROCEDURE_DETAILS_VIEW_MESSAGE_HEADING} title2={strings.PROCEDURE_DETAILS_VIEW_MESSAGE_BODY} >
                <ProcedureDetailsView nextPage={this.goToNextPage} prevPage={this.goToPrevPage} nextButtonText={this.state.isFromDataConfirmPage ? strings.SERVICE_AGREEMENT_VIEW_BUTTON_TEXT : strings.PROCEDURE_DETAILS_VIEW_BUTTON_TEXT} />
              </Wrapper>;
      case Constants.SERVICE_AGREEMENT_VIEW:
        return <Wrapper nextPage={this.goToNextPage} prevPage={PROCEDURE_DETAILS_VIEW} title1={strings.SERVICE_AGREEMENT_VIEW_MESSAGE_HEADING} >
                <ServiceAgreementView nextPage={this.goToNextPage} prevPage={this.goToPrevPage} nextButtonText={strings.SERVICE_AGREEMENT_VIEW_BUTTON_TEXT} />
              </Wrapper>;
      case Constants.DATA_CONFIRM_VIEW:
        return <Wrapper nextPage={this.goToNextPage} prevPage={SERVICE_AGREEMENT_VIEW} title1="Is this all correct?">
                <DataConfirmView nextPage={this.goToNextPage} prevPage={this.goToPrevPage} />
              </Wrapper>;
      case Constants.EMAIL_VERIFY_VIEW:
        return <Wrapper nextPage={this.goToNextPage} prevPage={NAME_VIEW} title1={`Thanks, ${this.props.firstName}!`}>
                <EmailOTPVerifyView nextPage={this.goToNextPage} prevPage={this.goToPrevPage} />
              </Wrapper>;
      case Constants.PHONE_VERIFY_VIEW:
        return <Wrapper nextPage={this.goToNextPage} prevPage={DATA_CONFIRM_VIEW} title1={`Thanks, ${this.props.firstName}!`}>
                <MobileOTPVerifyView nextPage={this.goToNextPage} prevPage={this.goToPrevPage} />
              </Wrapper>;
      case Constants.PASSWORD_VIEW:
        return <Wrapper nextPage={this.goToNextPage} prevPage={DATA_CONFIRM_VIEW} title1="Sign Up">
                <PasswordView nextPage={this.goToNextPage} prevPage={this.goToPrevPage} />
              </Wrapper>;
      case Constants.PROCEDURE_VERIFICATION_VIEW:
        return <Wrapper nextPage={this.goToNextPage} prevPage={DATA_CONFIRM_VIEW} title1="Verify Procedure">
                <ProcedureVerificationView nextPage={this.goToNextPage} prevPage={this.goToPrevPage} />
              </Wrapper>;
      case Constants.SUCCESS_VIEW:
        return <SuccessView nextPage={this.goToNextPage} />
      case Constants.LOGIN_VIEW:
        return <Wrapper nextPage={this.goToNextPage} prevPage={WELCOME_VIEW} title1="Login">
                <LoginView nextPage={this.goToNextPage} prevPage={this.switchToSignUpFlow}  />
              </Wrapper>;
      case Constants.LOGGED_IN_USER_PROCEDURE_VIEW:
        return <Wrapper nextPage={this.goToNextPage} prevPage={WELCOME_VIEW} title1={this.state.loggedInProcedureDetailsTitle} >
                <LoggedInUserProcedureView nextPage={this.goToNextPage} prevPage={this.switchToSignUpFlow} changeloggedInProcedureDetailsTitle = {this.changeloggedInProcedureDetailsTitle}  />
              </Wrapper>;
      case Constants.FORGOT_PASSWORD_VIEW:
        return <Wrapper nextPage={this.goToNextPage} prevPage={LOGIN_VIEW} title1="Forgot Password?">
                <ForgotPasswordView nextPage={this.goToNextPage} prevPage={this.switchToSignUpFlow}  />
              </Wrapper>;
      case Constants.PASSWORD_RESET_SUCCESS_VIEW:
        return <Wrapper nextPage={this.goToNextPage} showBanner={true} bannerMessage="Please do not close or refresh this page." prevPage={FORGOT_PASSWORD_VIEW}  title1="Forgot Password?">
                <PasswordResetSuccessView nextPage={this.goToNextPage} prevPage={this.switchToSignUpFlow}  />
              </Wrapper>;
    };
    // }
   }

  render() {
    return (
      <div>
        <LightboxModal show={this.state.isLoading} />
        {this.state.page === Constants.WELCOME_VIEW ? (
          <WelcomeView nextPage={this.goToNextPage} changeFlow={this.switchToAddProcedureFlow}/>
        ):(
          this.renderView()
        )}

      </div>

    );
  }
}


PatientRegister.propTypes = {
  isLoading: PropTypes.bool,
  procedure: PropTypes.string,
  facilityName: PropTypes.string,
  page: PropTypes.number,
  surgeonOptions: PropTypes.any,
  // cardiologistOptions: PropTypes.any,
  // procedure: PropTypes.any,
  fetchLocationProviders: PropTypes.func,
  // generateOtp: PropTypes.func,
  verifyOtp: PropTypes.func,
  // pageChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  requestOtp: PropTypes.func,
  submitPatientDetails: PropTypes.func,
  generalCardiologists: PropTypes.any,
  getFlowId: PropTypes.func,
  isMfaEnabled: PropTypes.bool,
  flowId: PropTypes.string,
  existingUser: PropTypes.bool,
};

 const mapStateToProps = (state) => ({
  isLoading: state.register.patientRegisterReducer.isLoading || state.currentUser.isLoading || state.register.reducer.isLoading,
  page: state.register.patientRegisterReducer.page,
  phone: state.register.patientRegisterReducer.phone,
  firstName: state.register.patientRegisterReducer.firstName,
  flowType: state.register.patientRegisterReducer.flowType,
  flowId:
    state.register.patientRegisterReducer.flowId,
  isMfaEnabled: state.register.patientRegisterReducer.isMfaEnabled,
  existingUser: state.register.patientRegisterReducer.existingUser,
  currentUser: state.currentUser.attributes
});

 const mapDispatchToProps = (dispatch) => ({
  setQRCodedetailsInState: (procedure, facilityName) =>
    dispatch(setQRCodedetails(procedure, facilityName)),
  handlePageChange: (page, redirect = true) =>
    dispatch(pageChange(page, redirect)),
  getFlowId: (params) => dispatch(getFlowId(params)),
  generatePhoneOtp: (params,nextPage,redirect=true) => dispatch(generateOtp(params,nextPage,redirect)),
  getAllLocations: () => dispatch(getAllLocationsRequest()),
  getAllProceduresModifiers: () => dispatch(getAllProceduresModifiersRequest()),
});


const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: "register",
  reducer: combineReducers({
    reducer,patientRegisterReducer,
    allLocationsReducer,allProceduresModifiersReducer
  }),
});

export default compose(withReducer, withConnect)(PatientRegister);

