/**
 * Combine all reducers in this file and export the combined reducers.
 */

import {combineReducers} from "redux";
import authReducer, {privacyNoticeReducer, userInfoReducer,} from "common/authReducers";
import {toasts} from "common/toasterReducers";
import patientInfo from "containers/PatientDetails/reducer";
import patientProceduresReducer, {switchProcedureReducer,} from "patientApp/common/reducers";
import passiveModeReducer from "patientApp/containers/PassiveMode/reducers";
import patientCardReducer from "common/patientCardReducer";
import shareOutcomesReportReducer from "common/shareOutcomesReportReducer";
import {
  addNewProcedureReducer,
  getProcedureLocationsReducer,
  getProcedureProvidersReducer,
  saveSearchFiltersReducer,
} from "common/reducer";
import {patientSearchReducer} from "containers/PatientSearch/reducer";
import {localHistory as history} from "./index";

import profileTabReducer from "containers/DashboardProfile/reducer";
import {patientRegisterReducer} from "./containers/PatientRegister/reducers";
import forgotPasswordReducer from "./containers/ForgotPassword/reducer";

export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    toasters: toasts,
    currentPatientInfo: patientInfo,
    currentUser: authReducer,
    profile: userInfoReducer,
    patientProcedures: patientProceduresReducer,
    activeProcedureDetails: switchProcedureReducer,
    prov: getProcedureProvidersReducer,
    loc: getProcedureLocationsReducer,
    addProcedureRequest: addNewProcedureReducer,
    search: patientSearchReducer,
    customFilter: saveSearchFiltersReducer,
    passiveMode: passiveModeReducer,
    patientCard: patientCardReducer,
    shareOutcomesReport: shareOutcomesReportReducer,
    profileCardDetails: profileTabReducer,
    patientRegister: patientRegisterReducer,
    forgotPassword: forgotPasswordReducer,
    privacyNotice: privacyNoticeReducer,
    ...injectedReducers,
  });
}
