import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "core-js/es";

import "sanitize.css/sanitize.css";
import "./App.css";
import {
  setupBasicInterceptor,
  setupInterceptor,
  setupNonMasqueradingInterceptor,
} from "services/base";

// Import root src
import {
  getCurrentUserInfo,
  sendHeartBeatsAgain,
} from "common/authThunkActions";
import App from "containers/App";

// TODO: Check potential issue with manifest and .htaccess
// Load the favicon, the manifest.json file and the .htaccess file

import "bootstrap/dist/css/bootstrap.min.css";

import "smart-app-banner/dist/smart-app-banner.css";

import configureStore from "./configureStore";

// Import CSS reset and Global Styles
import "./assets/stylesheets/bootstrap.scss";
import "./assets/stylesheets/datetime.scss";
import "./assets/stylesheets/mms_common.scss";
import "./assets/stylesheets/_fonts.scss";
import "./assets/stylesheets/_font_icons.scss";
import "./assets/stylesheets/_font_icons_b.scss";
import "./assets/stylesheets/_font_icons_z.scss";
import "./assets/stylesheets/_font_icon_multiselect.scss";

import "./assets/stylesheets/smart_banner.scss";

import { createBrowserHistory } from "history";

// // if (!window.matchMedia) {
// require('matchmedia-polyfill');// eslint-disable-line global-require
// require('matchmedia-polyfill/matchMedia.addListener');// eslint-disable-line global-require
// }

if (process.env.NODE_ENV !== "development") {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

// if (process.env.NODE_ENV !== 'production') {
//   const { whyDidYouUpdate } = require('why-did-you-update'); // eslint-disable-line global-require
//   whyDidYouUpdate(React);
// }

// Create redux store with history
const initialState = {};
export const localHistory = createBrowserHistory();
const store = configureStore(initialState, localHistory);

/**
 * Setting interceptor is called on page refresh or initial page load.
 * 1. Sets headers in request if axios does not contain the common headers
 * 2. Handles network errors.
 * 3. Camelcasing of response data.(setupInterceptor)
 */
setupBasicInterceptor(store);
setupInterceptor(store);
setupNonMasqueradingInterceptor(store);

getCurrentUserInfo(store);
sendHeartBeatsAgain();

const MOUNT_NODE = document.getElementById("root");

const root = createRoot(MOUNT_NODE);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// const render = () => {
//   ReactDOM.render(
//     <Provider store={store}>
//       <ConnectedRouter history={history}>
//         <App />
//       </ConnectedRouter>
//     </Provider>,
//     MOUNT_NODE
//   );
// };

// if (module.hot) {
//   // Hot reloadable React components and translation json files
//   // modules.hot.accept does not accept dynamic dependencies,
//   // have to be constants at compile-time
//   module.hot.accept(["containers/App"], () => {
//     ReactDOM.unmountComponentAtNode(MOUNT_NODE);
//     render();
//   });
// }

// render();

// new SmartBanner({ // eslint-disable-line no-new
//   daysHidden: 15, // days to hide banner after close button is clicked (defaults to 15)
//   daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
//   appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
//   title: 'Secure Start℠',
//   author: 'Higgs Boson Inc',
//   button: 'VIEW',
//   icon: appBannerIcon,
//   store: {
//     ios: 'On the App Store',
//     android: 'In Google Play',
//     windows: 'In Windows store',
//   },
//   price: {
//     ios: 'GET',
//     android: 'GET',
//     windows: 'GET',
//   },
// });
