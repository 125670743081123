/*
 *
 * Common Constants
 *
 */

export const GET_DOC_AUTHORIZED_URL_REQUEST_SENT = 'src/Common/GET_DOC_AUTHORIZED_URL_REQUEST_SENT';
export const GET_DOC_AUTHORIZED_URL_REQUEST_SUCCEED = 'src/Common/GET_DOC_AUTHORIZED_URL_REQUEST_SUCCEED';
export const GET_DOC_AUTHORIZED_URL_REQUEST_FAILED = 'src/Common/GET_DOC_AUTHORIZED_URL_REQUEST_FAILED';

export const DOCUMENT_UPLOAD_REQUEST_SENT = 'src/Common/DOCUMENT_UPLOAD_REQUEST_SENT';
export const DOCUMENT_UPLOAD_REQUEST_SUCCEED = 'src/Common/DOCUMENT_UPLOAD_REQUEST_SUCCEED';
export const DOCUMENT_UPLOAD_REQUEST_FAILED = 'src/Common/DOCUMENT_UPLOAD_REQUEST_FAILED';

export const GET_PROCEDURE_LOCATIONS_REQUEST_SENT = 'src/Common/GET_PROCEDURE_LOCATIONS_REQUEST_SENT';
export const GET_PROCEDURE_LOCATIONS_REQUEST_SUCCEED = 'src/Common/GET_PROCEDURE_LOCATIONS_REQUEST_SUCCEED';
export const GET_PROCEDURE_LOCATIONS_REQUEST_FAILED = 'src/Common/GET_PROCEDURE_LOCATIONS_REQUEST_FAILED';

export const GET_PROCEDURE_PROVIDERS_REQUEST_SENT = 'src/Common/GET_PROCEDURE_PROVIDERS_REQUEST_SENT';
export const GET_PROCEDURE_PROVIDERS_REQUEST_SUCCEED = 'src/Common/GET_PROCEDURE_PROVIDERS_REQUEST_SUCCEED';
export const GET_PROCEDURE_PROVIDERS_REQUEST_FAILED = 'src/Common/GET_PROCEDURE_PROVIDERS_REQUEST_FAILED';

export const GET_PROCEDURE_MODIFIERS_REQUEST_SENT = 'src/Common/GET_PROCEDURE_MODIFIERS_REQUEST_SENT';
export const GET_PROCEDURE_MODIFIERS_REQUEST_SUCCEED = 'src/Common/GET_PROCEDURE_MODIFIERS_REQUEST_SUCCEED';
export const GET_PROCEDURE_MODIFIERS_REQUEST_FAILED = 'src/Common/GET_PROCEDURE_MODIFIERS_REQUEST_FAILED';

export const UPDATE_PROCEDURE_MODIFIERS_REQUEST_SENT = 'src/Common/UPDATE_PROCEDURE_MODIFIERS_REQUEST_SENT';
export const UPDATE_PROCEDURE_MODIFIERS_REQUEST_SUCCEED = 'src/Common/UPDATE_PROCEDURE_MODIFIERS_REQUEST_SUCCEED';
export const UPDATE_PROCEDURE_MODIFIERS_REQUEST_FAILED = 'src/Common/UPDATE_PROCEDURE_MODIFIERS_REQUEST_FAILED';

export const SHOW_TOASTER = 'src/Common/SHOW_TOASTER';
export const REMOVE_TOASTER = 'src/Common/REMOVE_TOASTER';

export const GET_DEPARTMENT_PROCEDURES_REQUEST_SENT = 'src/Common/GET_DEPARTMENT_PROCEDURES_REQUEST_SENT';
export const GET_DEPARTMENT_PROCEDURES_REQUEST_SUCCEED = 'src/Common/GET_DEPARTMENT_PROCEDURES_REQUEST_SUCCEED';
export const GET_DEPARTMENT_PROCEDURES_REQUEST_FAILED = 'src/Common/GET_DEPARTMENT_PROCEDURES_REQUEST_FAILED';
export const DOCUMENT_UPLOAD_CLEAR_STATE = 'src/Common/DOCUMENT_UPLOAD_CLAEAR_STATE';
export const DOCUMENT_UPLOAD_REMOVE_DOCUMENT = 'src/Common/DOCUMENT_UPLOAD_REMOVE_DOCUMENT';

export const SAVE_SEARCH_FILTERS_SENT = 'src/Common/SAVE_SEARCH_FILTERS_SENT';
export const SAVE_SEARCH_FILTERS_SUCCEED = 'src/Common/SAVE_SEARCH_FILTERS_SUCCEED';
export const SAVE_SEARCH_FILTERS_FAILED = 'src/Common/SAVE_SEARCH_FILTERS_FAILED';

export const UPDATE_SEARCH_FILTERS_SENT = 'src/Common/UPDATE_SEARCH_FILTERS_SENT';
export const UPDATE_SEARCH_FILTERS_SUCCEED = 'src/Common/UPDATE_SEARCH_FILTERS_SUCCEED';
export const UPDATE_SEARCH_FILTERS_FAILED = 'src/Common/UPDATE_SEARCH_FILTERS_FAILED';

export const DELETE_SEARCH_FILTERS_SENT = 'src/Common/DELETE_SEARCH_FILTERS_SENT';
export const DELETE_SEARCH_FILTERS_SUCCEED = 'src/Common/DELETE_SEARCH_FILTERS_SUCCEED';
export const DELETE_SEARCH_FILTERS_FAILED = 'src/Common/DELETE_SEARCH_FILTERS_FAILED';

export const GET_DEPARTMENT_PROVIDERS_REQUEST_SENT = 'src/Common/GET_DEPARTMENT_PROVIDERS_REQUEST_SENT';
export const GET_DEPARTMENT_PROVIDERS_REQUEST_SUCCEED = 'src/Common/GET_DEPARTMENT_PROVIDERS_REQUEST_SUCCEED';
export const GET_DEPARTMENT_PROVIDERS_REQUEST_FAILED = 'src/Common/GET_DEPARTMENT_PROVIDERS_REQUEST_FAILED';

export const ADD_NEW_PROCEDURE_REQUEST_SENT = 'src/Dashboard/ADD_NEW_PROCEDURE_REQUEST_SENT';
export const ADD_NEW_PROCEDURE_REQUEST_SUCCEED = 'src/Dashboard/ADD_NEW_PROCEDURE_REQUEST_SUCCEED';
export const ADD_NEW_PROCEDURE_REQUEST_FAILED = 'src/Dashboard/ADD_NEW_PROCEDURE_REQUEST_FAILED';

export const CLEAR_ADD_PROCEDURE_STATES_REQUEST = 'src/Dashboard/CLEAR_ADD_PROCEDURE_STATES_REQUEST';

export const GET_VERIFICATION_CODE_REQUEST_SENT = 'src/Registration/GET_VERIFICATION_CODE_REQUEST_SENT';
export const GET_VERIFICATION_CODE_REQUEST_SUCCEED = 'src/Registration/GET_VERIFICATION_CODE_REQUEST_SUCCEED';
export const GET_VERIFICATION_CODE_REQUEST_FAILED = 'src/Registration/GET_VERIFICATION_CODE_REQUEST_FAILED';
export const CLEAR_VERIFICATION_CODE = 'src/Registration/CLEAR_VERIFICATION_CODE';

export const VERIFY_OTP_REQUEST_SENT = 'src/Registration/VERIFY_OTP_REQUEST_SENT';
export const VERIFY_OTP_REQUEST_SUCCEED = 'src/Registration/VERIFY_OTP_REQUEST_SUCCEED';
export const VERIFY_OTP_REQUEST_FAILED = 'src/Registration/VERIFY_OTP_REQUEST_FAILED';
export const CLEAR_VERIFY_OTP = 'src/Registration/CLEAR_VERIFY_OTP';

export const GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_SENT = 'src/Common/GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_SENT';
export const GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_SUCCEED = 'src/Common/GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_SUCCEED';
export const GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_FAILED = 'src/Common/GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_FAILED';

export const GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_SENT = 'src/Common/GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_SENT';
export const GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_SUCCEED = 'src/Common/GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_SUCCEED';
export const GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_FAILED = 'src/Common/GGET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_FAILED';


export const GET_LOCATION_REQUEST_SENT = 'src/Common/GET_LOCATION_REQUEST_SENT';
export const GET_LOCATION_REQUEST_SUCCEED = 'src/Common/GET_LOCATION_REQUEST_SUCCEED';
export const GET_LOCATION_REQUEST_FAILED = 'src/Common/GET_LOCATION_REQUEST_FAILED';

export const GET_ALL_LOCATIONS_REQUEST_SENT = 'app/Common/GET_ALL_LOCATIONS_REQUEST_SENT';
export const GET_ALL_LOCATIONS_REQUEST_SUCCEED = 'app/Common/GET_ALL_LOCATIONS_REQUEST_SUCCEED';
export const GET_ALL_LOCATIONS_REQUEST_FAILED = 'app/Common/GET_ALL_LOCATIONS_REQUEST_FAILED';

export const GET_ALL_PROCEDURES_MODIFIERS_REQUEST_SENT = 'app/Common/GET_ALL_PROCEDURES_MODIFIERS_REQUEST_SENT';
export const GET_ALL_PROCEDURES_MODIFIERS_REQUEST_SUCCEED= 'app/Common/GET_ALL_PROCEDURES_MODIFIERS_REQUEST_SUCCEED';
export const GET_ALL_PROCEDURES_MODIFIERS_REQUEST_FAILED = 'app/Common/GET_ALL_PROCEDURES_MODIFIERS_REQUEST_FAILED';

export const VALIDATE_EMAIL_UNIQUENESS_REQUEST_SENT = 'src/Common/VALIDATE_EMAIL_UNIQUENESS_REQUEST_SENT';
export const VALIDATE_EMAIL_UNIQUENESS_REQUEST_SUCCEED = 'src/Common/VALIDATE_EMAIL_UNIQUENESS_REQUEST_SUCCEED';
export const VALIDATE_EMAIL_UNIQUENESS_REQUEST_FAILED = 'src/Common/VALIDATE_EMAIL_UNIQUENESS_REQUEST_FAILED';

export const VALIDATE_PHONE_UNIQUENESS_REQUEST_SENT = 'src/Common/VALIDATE_PHONE_UNIQUENESS_REQUEST_SENT';
export const VALIDATE_PHONE_UNIQUENESS_REQUEST_SUCCEED = 'src/Common/VALIDATE_PHONE_UNIQUENESS_REQUEST_SUCCEED';
export const VALIDATE_PHONE_UNIQUENESS_REQUEST_FAILED = 'src/Common/VALIDATE_PHONE_UNIQUENESS_REQUEST_FAILED';


export const CLEAR_DATA = 'src/Common/CLEAR_DATA';

export const POST_SEND_INVITES_REQUEST_SENT = 'src/PATIENT_CARD/POST_SEND_INVITES_REQUEST_SENT';
export const POST_SEND_INVITES_REQUEST_SUCCEED = 'src/PATIENT_CARD/POST_SEND_INVITES_REQUEST_SUCCEED';
export const POST_SEND_INVITES_REQUEST_FAILED = 'src/PATIENT_CARD/POST_SEND_INVITES_REQUEST_FAILED';

export const DISCARD_PROCEDURES_REQUEST_SENT = 'src/PATIENT_CARD/DISCARD_PROCEDURES_REQUEST_SENT';
export const DISCARD_PROCEDURES_REQUEST_SUCCEED = 'src/PATIENT_CARD/DISCARD_PROCEDURES_REQUEST_SUCCEED';
export const DISCARD_PROCEDURES_REQUEST_FAILED = 'src/PATIENT_CARD/DISCARD_PROCEDURES_REQUEST_FAILED';

export const GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_SENT = 'src/Common/GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_SENT';
export const GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_SUCCEED = 'src/Common/GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_SUCCEED';
export const GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_FAILED = 'src/Common/GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_FAILED';

export const SHARE_OUTCOMES_REPORT_REQUEST_SENT = "src/Common/SHARE_OUTCOMES_REPORT_REQUEST_SENT";
export const SHARE_OUTCOMES_REPORT_REQUEST_SUCCEED = "src/Common/SHARE_OUTCOMES_REPORT_REQUEST_SUCCEED";
export const SHARE_OUTCOMES_REPORT_REQUEST_FAILED = "src/Common/SHARE_OUTCOMES_REPORT_REQUEST_FAILED";

export const GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SENT = "src/Common/GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SENT";
export const GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SUCCEED = "src/Common/GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SUCCEED";
export const GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_FAILED = "src/Common/GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_FAILED";

export const GET_SEARCH_CATEGORY_LIST_REQUEST_SENT = "src/Common/GET_SEARCH_CATEGORY_LIST_REQUEST_SENT";
export const GET_SEARCH_CATEGORY_LIST_REQUEST_SUCCEED = "src/Common/GET_SEARCH_CATEGORY_LIST_REQUEST_SUCCEED";
export const GET_SEARCH_CATEGORY_LIST_REQUEST_FAILED = "src/Common/GET_SEARCH_CATEGORY_LIST_REQUEST_FAILED";
