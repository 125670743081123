import React, { PureComponent } from 'react';
import './style.scss';
import Strings from '../../../strings'

class PassiveModeFooter extends PureComponent {// eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <div className="passive-mode-footer">
        <div className="title">The Secure Start℠ Team</div>
        <div className="subtitle">Questions and technical support:</div>
        <div>
          <a href={`tel:${Strings.SUPPORT_PHONE_NUMBER}`}>{Strings.SUPPORT_PHONE_NUMBER}</a>
          &nbsp;|&nbsp;
          <a href="mailto:support@us.securestart.app">{Strings.SUPPORT_EMAIL}</a>
        </div>
      </div>
    );
  }
}


export default PassiveModeFooter;
