import React from "react";
import Stack from "@mui/material/Stack";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import InputTextField from "./../../../components/StyleGuideComponents/InputTextField";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import { patientRegisterReducer} from "./../reducers";
import { privacyNoticeReducer } from "../../../common/authReducers";
import validate from "common/validator";
import { nameViewValidation } from "./../validators";
import { ADDRESS_VIEW } from "./../constants";
import { withRouter } from 'react-router';
import { validateEmailAndPhone } from "../../../services/patientDetails";
import { trimCountryCodeFromPhoneNumber } from 'utils/stringUtils';
import { removeToaster } from "../../../common/toasterActions";
import { setSignUpData } from "../action";

class NameView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.firstName || "",
      lastName: props.lastName || "",
      email: props.email || "",
      phone: props.phone || "",
      errors: []
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if( nextProps.toasters === this.props.toasters ){
      const formData = {};
      Object.keys(nextProps).forEach((key) => {
        if (key in this.state) {

          if (nextProps[key] != this.state[key]) {
            formData[key] = nextProps[key];
          }
        }
      });
      if (Object.keys(formData).length) {
        this.setState({ ...formData });
      }
  }
  };

  componentDidMount= () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  restrictCharacters = (valueToInsert) => {
    let asciiValue = valueToInsert.slice(-1).charCodeAt(0)
    if(asciiValue > 47 && asciiValue < 58){
      this.setState({phone: valueToInsert})
    }
    else{
      this.setState({phone: valueToInsert.substring(0,valueToInsert.length-1)})
    }
  }

  restrictCharacters = (value) => {
    let asciiValue = value.slice(-1).charCodeAt(0)
    return (asciiValue > 47 && asciiValue < 58) ? value : value.substring(0,value.length-1);
  }

   appendCountryCode = (value) => {

    if(process.env.REACT_APP_COUNTRY_OF_APPLICATION === "USA"){
      return "+1"+value;
    }
    if(process.env.REACT_APP_COUNTRY_OF_APPLICATION === "UK"){
      return "+44"+value;

    }
  }

  handleChange = (name, value) => {
    let errors = this.state.errors
    delete errors[name]
    this.setState({ [name]: value,errors:errors });
  };

  onValidationFailure = (errors) => {
    this.setState({ errors: errors });
  };

  onNextValidationSuccess = () => {
      this.props.toasters.map((toast) => this.props.removeToast(toast.id));
      this.props.setSignUpDataInStore(this.state, this.props.nextPage, ADDRESS_VIEW);
  };

  checkIfButtonEnabled = ()  => {
    return this.state.firstName.length && this.state.lastName.length && this.state.email.length && this.state.phone.length;
  }



  onAgreeOfDisclaimer = () => {
    this.setState({ serviceEnrollmentModal: false });
  };

  onDisagreeOfDisclaimer = () => {
    this.setState({ serviceEnrollmentModal: false },()=>{
      this.props.history.goBack();
      this.props.history.push(this.props.match.url)
      window.location.reload();
    });
  };

  onDisclaimerClose = () => {
    this.setState({ serviceEnrollmentModal: false });
  };

  handleSubmit =  (e) => {
    e.preventDefault();
      validate(
        nameViewValidation,
        this.state,
        this.onValidationFailure,
        this.onNextValidationSuccess
        );
  };

  render() {
    return (
      <div style={{paddingBottom:"-6%"}}>

        <form type="post">
          <div>
            <Stack sx={{zIndex:"0"}} spacing={"24px"}>

              <InputTextField
                id="firstName"
                label="First Name"
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleChange}
                required
                error={"firstName" in this.state.errors}
                errorText={
                  "firstName" in this.state.errors
                    ? this.state.errors.firstName
                    : ""
                }
              />

              <InputTextField
                id="lastName"
                label="Last Name"
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleChange}
                required
                error={"lastName" in this.state.errors}
                errorText={
                  "lastName" in this.state.errors
                    ? this.state.errors.lastName
                    : ""
                }
              />

              <InputTextField
                id="email"
                label="Email Address"
                name="email"
                type="email"
                value={this.state.email}
                onChange={this.handleChange}
                required
                error={"email" in this.state.errors}
                errorText={
                  "email" in this.state.errors ? this.state.errors.email : ""
                }
              />

              <InputTextField
                id="phone"
                label="Mobile Number"
                name="phone"
                type="phone"
                value={this.state.phone}
                onChange={this.handleChange}
                required
                error={"phone" in this.state.errors}
                errorText={
                  "phone" in this.state.errors ? this.state.errors.phone : ""
                }
              />

            </Stack>
            <div className="continue-button-for-form">
              <PrimaryButton
              disabled={!this.checkIfButtonEnabled()}
                fullWidth
                text={this.props.nextButtonText}
                onClick={this.handleSubmit}
              />
              </div>
          </div>
        </form>
      </div>
    );
  }
}

NameView.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
};

const mapStateToProps = (state) => ({
  firstName: state.patientRegister.patientRegisterReducer.firstName,
  lastName: state.patientRegister.patientRegisterReducer.lastName,
  email: state.patientRegister.patientRegisterReducer.email,
  phone: state.patientRegister.patientRegisterReducer.phone,
  toasters: state.toasters
});

const mapDispatchToProps = (dispatch) => ({
  setSignUpDataInStore: (data, nextPage, page) =>
    dispatch(setSignUpData(data, nextPage, page)),
  removeToast: (id) => 
    dispatch(removeToaster(id))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "patientRegister",
  reducer: combineReducers({
    patientRegisterReducer,
    privacyNoticeReducer,
  }),
});

export default compose(withReducer, withRouter,withConnect)(NameView);

