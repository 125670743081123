import React from "react";
import "../patientRegister.scss";
import PropTypes from "prop-types";
import STRINGS from "../../../strings";
import Stack from "@mui/material/Stack";
import {connect} from "react-redux";
import {combineReducers, compose} from "redux";
import injectReducer from "utils/injectReducer";
import {patientRegisterReducer} from "../reducers";
import InputTextField from "../../../components/StyleGuideComponents/InputTextField";
import PrimaryButton from "../../../components/StyleGuideComponents/PrimaryButton";
import {generateOtp, verifyOtp} from "../action";
import validate from "common/validator";
import {emailOtpValidationConfig} from "./../validators";
import {setOtpInState} from './../action'
import {PASSWORD_VIEW} from "./../constants";

class EmailOTPVerifyView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            flowId: props.flowId || null,
            flowType: props.flowType || "sign_up",
            firstName: props.firstName || "",
            emailOtp: props.emailOtp || "",
            email: props.email || "",
            timer: 60,
            errors: [],
            phone: props.phone || "",
            zipCode: props.zipCode || "",
        };
    }

    handleOTP = (isResend) => {
        const params = {
            flow_type: this.state.flowType,
            flow_id: this.state.flowId,
            email: this.state.email,
        };
        this.props.generateEmailOtp(params, this.props.nextPage, "", false, isResend);
    };

    startTimer = () => {
        this.timer = setInterval(() => {
            if (this.state.timer > 0) this.setState({timer: this.state.timer - 1});
        }, 1000);
    }

    componentDidMount() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        this.handleOTP(false);
        this.startTimer();
    }

    componentDidUpdate() {
        if (this.state.timer === 0) {
            clearInterval(this.timer);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.firstName != nextProps.firstName) {
            this.setState({firstName: nextProps.firstName});
        }
        if (this.state.phone != nextProps.phone) {
            this.setState({phone: nextProps.phone});
        }
        if (this.state.email != nextProps.email) {
            this.setState({email: nextProps.email});
        }
    }

    handleChange = (name, value) => {
        this.setState({[name]: value});
    };
    onValidationFailure = (errors) => {
        this.setState({errors: errors});
    };

    onNextValidationSuccess = () => {
        const params = {
            verification_code: this.state.emailOtp,
            flow_type: this.state.flowType,
            flow_id: this.state.flowId,
            email: this.state.email,
        };
        this.props.setOtpInReduxState({emailOtp: this.state.emailOtp})
        this.props.verifyEmailOtp(params, this.props.nextPage, PASSWORD_VIEW);
        // this.props.nextPage();
    };

    validateOTP = (e) => {
        e.preventDefault();
        validate(
            emailOtpValidationConfig,
            this.state,
            this.onValidationFailure,
            this.onNextValidationSuccess
        );
    };

    resetTimer = () => {
        this.handleOTP(true);
        this.setState({timer: 60}, () => this.startTimer())
    }

    render() {
        return (
            <div className="text-center">
                <Stack spacing={"8px"}>
                    <div>
                        <div className="otp-message">
                            {STRINGS.PATIENT_REGISTER_EMAIL_VERIFICATION_PROMPT}
                            <br/>
                            <span className="verification-highlight">{this.props.email}</span>
                        </div>
                    </div>
                    <InputTextField
                        id="emailOtp"
                        label="Verification Code"
                        name="emailOtp"
                        value={this.state.emailOtp}
                        onChange={(name, value) => this.handleChange(name, value)}
                        required
                        error={"emailOtp" in this.state.errors}
                        errorText={
                            "emailOtp" in this.state.errors ? this.state.errors.emailOtp : ""
                        }
                    />
                    <div className="resend-message">
                        {this.state.timer > 0 ?
                            (
                                <div className="show-resend-code">Resend code in {this.state.timer} seconds</div>) :
                            (
                                <div className="not-show-resend-code">Resend code in {this.state.timer} seconds</div>
                            )
                        }

                        {
                            this.state.timer === 0 ? (
                                <div onClick={() => this.resetTimer()}
                                     className="cursor-pointer highlight-text resend-code">
                                    Resend code
                                </div>
                            ) : (
                                <div className="cursor-disabled resend-code-disabled-text resend-code">
                                    Resend code
                                </div>
                            )}


                    </div>
                    <PrimaryButton disabled={this.state.emailOtp.length <= 0} fullWidth text="Verify"
                                   onClick={this.validateOTP}/>
                </Stack>
            </div>
            //   </div>
            // </div>
        );
    }
}

EmailOTPVerifyView.propTypes = {
    isLoading: PropTypes.bool,
    firstName: PropTypes.string,
    email: PropTypes.string,
};

const mapStateToProps = (state) => ({
    firstName: state.patientRegister.patientRegisterReducer.firstName,
    email: state.patientRegister.patientRegisterReducer.email,
    phone: state.patientRegister.patientRegisterReducer.phone,
    zipCode: state.patientRegister.patientRegisterReducer.zipCode,
    flowType: state.patientRegister.patientRegisterReducer.flowType,
    flowId:
    state.patientRegister.patientRegisterReducer.flowId,
});

const mapDispatchToProps = (dispatch) => ({
    generateEmailOtp: (params, nextPage, page, redirect = true, isResend = false) =>
        dispatch(generateOtp(params, nextPage, page, redirect, isResend)),
    setOtpInReduxState: (params) =>
        dispatch(setOtpInState(params)),
    generatePhoneOtp: (params, nextPage) =>
        dispatch(generateOtp(params, nextPage)),
    verifyEmailOtp: (params, nextPage, page) => dispatch(verifyOtp(params, nextPage, page)),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
    key: "patientRegister",
    reducer: combineReducers({
        patientRegisterReducer,
    }),
});

export default compose(withReducer, withConnect)(EmailOTPVerifyView);
