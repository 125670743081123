// Page orders
export const WELCOME_VIEW = 1;

export const NAME_VIEW = 2;

export const ADDRESS_VIEW = 3;

export const PROCEDURE_DATES_VIEW = 4;

export const PROCEDURE_DETAILS_VIEW = 5;

export const SERVICE_AGREEMENT_VIEW = 6;

export const DATA_CONFIRM_VIEW = 7;

export const EMAIL_VERIFY_VIEW = 8;

export const PHONE_VERIFY_VIEW = 9;

export const PASSWORD_VIEW = 10;

export const SUCCESS_VIEW = 11;

export const LOGIN_VIEW = 12;

export const LOGGED_IN_USER_PROCEDURE_VIEW = 13;

export const FORGOT_PASSWORD_VIEW = 14;

export const PASSWORD_RESET_SUCCESS_VIEW = 15;

export const PROCEDURE_VERIFICATION_VIEW = 16;

export const GET_LOCATION_PROVIDERS_REQUEST_SENT =
    "app/PatientRegister/GET_LOCATION_PROVIDERS_REQUEST_SENT";
export const GET_LOCATION_PROVIDERS_REQUEST_SUCCEED =
    "app/PatientRegister/GET_LOCATION_PROVIDERS_REQUEST_SUCCEED";
export const GET_LOCATION_PROVIDERS_REQUEST_FAILED =
    "app/PatientRegister/GET_LOCATION_PROVIDERS_REQUEST_FAILED";

export const GENERATE_OTP_REQUEST_SENT =
    "app/PatientRegister/GENERATE_OTP_REQUEST_SENT";
export const GENERATE_OTP_REQUEST_SUCCEED =
    "app/PatientRegister/GENERATE_OTP_REQUEST_SUCCEED";
export const GENERATE_OTP_REQUEST_FAILED =
    "app/PatientRegister/GENERATE_OTP_REQUEST_FAILED";

export const VERIFY_OTP_REQUEST_SENT =
    "app/PatientRegister/VERIFY_OTP_REQUEST_SENT";
export const VERIFY_OTP_REQUEST_SUCCEED =
    "app/PatientRegister/VERIFY_OTP_REQUEST_SUCCEED";
export const VERIFY_OTP_REQUEST_FAILED =
    "app/PatientRegister/VERIFY_OTP_REQUEST_FAILED";

export const CREATE_PATIENT_REQUEST_SENT =
    "app/PatientRegister/CREATE_PATIENT_REQUEST_SENT";
export const CREATE_PATIENT_REQUEST_SUCCEED =
    "app/PatientRegister/CREATE_PATIENT_REQUEST_SUCCEED";
export const CREATE_PATIENT_REQUEST_FAILED =
    "app/PatientRegister/CREATE_PATIENT_REQUEST_FAILED";

export const DISPATCH_DEEPLINK_URL = 
    "app/PatientRegister/DISPATCH_DEEPLINK_URL";

export const SAVE_PROCEDURE_REQUEST_SENT =
    "app/PatientRegister/SAVE_PROCEDURE_REQUEST_SENT";
export const SAVE_PROCEDURE_REQUEST_SUCCEEDED =
    "app/PatientRegister/SAVE_PROCEDURE_REQUEST_SUCCEEDED";
export const SAVE_PROCEDURE_REQUEST_FAILED =
    "app/PatientRegister/SAVE_PROCEDURE_REQUEST_FAILED";

export const GET_FLOW_ID_REQUEST_SENT =
    "app/PatientRegister/GET_FLOW_ID_REQUEST_SENT";
export const GET_FLOW_ID_REQUEST_SUCCEED =
    "app/PatientRegister/GET_FLOW_ID_REQUEST_SUCCEED";
export const GET_FLOW_ID_REQUEST_FAILED =
    "app/PatientRegister/GET_FLOW_ID_REQUEST_FAILED";

export const PAGE_CHANGE = "app/PatientRegister/PAGE_CHANGE";

export const SET_QR_DETAILS = "app/PatientRegister/SET_QR_DETAILS";

export const SET_SIGNUP_DATA = "app/PatientRegister/SET_SIGNUP_DATA";
export const SET_SIGNUP_DATA_FOR_EXISTING_USER = "app/PatientRegister/SET_SIGNUP_DATA_FOR_EXISTING_USER";
export const SET_IS_LOADING = 'app/PatientRegister/RESET_IS_LOADING'


export const SET_EMAIL_OTP_IN_STATE = "app/PatientRegister/SET_EMAIL_OTP_IN_STATE";

export const CLEAR_SIGNUP_DATA = "app/PatientRegister/CLEAR_SIGNUP_DATA";

export const SET_PHONE_OTP_IN_STATE = "app/PatientRegister/SET_PHONE_OTP_IN_STATE";
export const SET_USER_CONSENT = "app/PatientRegister/SET_USER_CONSENT";
export const SET_PROCEDURE_CONFIRMATION = "app/PatientRegister/SET_PROCEDURE_CONFIRMATION";
export const SET_EXISTING_USER_FLAG = "app/PatientRegister/SET_EXISTING_USER_FLAG";
export const GET_MODIFIER_AND_FACILITY_DATA_REQUEST_SENT = "app/PatientRegister/GET_MODIFIER_AND_FACILITY_DATA_REQUEST_SENT";
export const GET_MODIFIER_AND_FACILITY_DATA_REQUEST_SUCCEEDEED = "app/PatientRegister/GET_MODIFIER_AND_FACILITY_DATA_REQUEST_SUCCEEDEED";
export const GET_MODIFIER_AND_FACILITY_DATA_REQUEST_FAILED = "app/PatientRegister/GET_MODIFIER_AND_FACILITY_DATA_REQUEST_FAILED";


